.content-wrapper {
  background-color: #ffffff;
  .title {
    font-weight: 600;
  }
  .insights-content {
    background-color: #ffffff;
    margin-top: -50px;
    padding: 25px;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
    .content-main {
      .report-item {
        h5 {
          font-size: 18px;
          text-transform: capitalize;
          font-weight: 600;
        }
      }
      &:hover .text-info {
        color: var(--link-color);
      }
    }
  }
}
