
.allflags {
	background-image: url(../../../public/common-icons.webp);
	width: 30px;
	height: 25px;
	margin-right: 4px;
	position: absolute;
	left: -37px;
	bottom: 1px;
}
.us-contact {
	a {
		text-decoration: none;
		font-size: 14px;
	}
}
.mail-id {
	text-decoration: none;
	font-size: 14px;
}
.us-flag {
	background-position: 0 0;
}
.uk-flag {
	background-position: 6% 0;
}
.jap-flag {
	background-position: 11% 0;
}
.ind-flag {
	background-position: 17% 0;
}
.sale-email {
	font-size: 14px;
}
b {
	font-weight: 900;
}
