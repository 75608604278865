$color_1: #fff;
$color_2: #000;
$color_3: #47b2e4;
$color_4: #ffff;
$color_5: #d6eefd;
$color_6: #034552;
$font-family_1: 'Jost', sans-serif;
$background-color_1: #020c1b;
$background-color_2: #fff;
$background-color_3: #034552;

/*Footer Section END CSS*/
footer {
  margin-top: 75px;
  background-color: $background-color_1;
  a {
    color: $color_1;
    padding: 7px 0;
    text-decoration: none;
    font-size: 14px;
    &:hover {
      color: $color_1;
    }
  }
  .contact-us-main {
    padding: 20px 25px;
    box-shadow: 2px 2px 12px 0px rgba(52, 52, 52, 0.4);
    margin-top: -80px;
    border-radius: 7px;
    .title {
      font-size: 16px;
      font-weight: 600;
      color: $color_2;
      margin-bottom: 0px;
      text-align: start;
    }
    .footitem {
      a {
        text-decoration: none;
        color: $color_2;
        font-size: 16px;
      }
      & {
        position: relative;
      }
      span.footphoneIcons {
        position: absolute;
        left: -40px;
        top: 28%;
      }
    }
  }
  .footlinks {
    .item {
      a {
        &:hover {
          color: $color_3;
        }
      }
      .title {
        color: $color_1;
        font-weight: 600;
        font-size: 16px;
      }
    }
    .socialmediaMain {
      .title {
        color: $color_1;
        font-weight: 600;
      }
      ul {
        li {
          a {
            width: 30px;
            display: flex;
            background-color: $background-color_2;
            padding: 3px;
            margin: 5px;
            justify-content: center;
            height: 30px;
            align-items: center;
            border-radius: 3px;
            svg {
              color: $color_6;
            }
            &:hover {
              svg {
                color: $color_1;
              }
              & {
                background-color: $background-color_3;
              }
              & {
                transform: scale(1.05);
              }
              & {
                transition: all 0.5s ease-in-out;
              }
            }
          }
        }
      }
    }
    .foot-address {
      svg {
        color: $color_1;
      }
    }
  }
  .footullinks {
    .title {
      color: $color_1;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .footcontinfos {
    .title {
      color: $color_1;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .foot-address {
    .title {
      color: $color_1;
      font-weight: 600;
    }
    address {
      margin-left: 10px;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0;
      font-family: $font-family_1;
      color: $color_4;
    }
    hr {
      color: $color_5;
    }
  }
}
.footullinks {
  a {
    display: block;
    color: $color_1;
    font-size: 14px;
    &:hover {
      color: $color_3;
    }
  }
}
.copyrightxfoot {
  background: #000000;
  padding: 10px;
  color: $color_1;
  text-align: center;
  font-size: 13px;
}
.p-lb-32 {
  padding: 0 32px;
}
